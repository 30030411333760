/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // toggles hamburger and nav open and closed states
        var removeClass = true;
        $(".hamburger").click(function () {
            $(".hamburger").toggleClass('is-active');
            $("#sideNav").toggleClass('sideNav-open');
            $(".sideNavBody").toggleClass('sideNavBody-push');
            removeClass = false;
        });

        $(".sideNav").click(function() {
            removeClass = false;
        });

        $("html").click(function () {
            if (removeClass) {
                $(".hamburger").removeClass('is-active');
                $("#sideNav").removeClass('sideNav-open');
                $(".sideNavBody").removeClass('sideNavBody-push');
            }
            removeClass = true;
        });

        $(window).scroll(function () {
            if ($(this).scrollTop() > 100) {
                $('.scrollup').fadeIn();
            } else {
                $('.scrollup').fadeOut();
            }
        });
        $('.scrollup').click(function () {
            $("html, body").animate({
                scrollTop: 0
            }, 600);
            return false;
        });

        $('#tvspot').click(function() {
          $('.modaltv').modal({
            type: 'primary'
          });
          return false;
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        function initialize_gmap() {

          var myLatlng = new google.maps.LatLng(44.906006, -68.69118100000003);

          var mapDiv = document.getElementById('google-map');
          var map = new google.maps.Map(mapDiv, {
            center: myLatlng,
            zoom: 16,
            scrollwheel: false,
            navigationControl: false,
            mapTypeControl: false,
            scaleControl: false,
            draggable: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP
          });

          var infowindow = new google.maps.InfoWindow({
            content: "Orono Trampoline Park"
          });

          var marker = new google.maps.Marker({
            position: myLatlng,
            map: map,
            title: "Orono Trampoline Park"
          });

          google.maps.event.addListener(marker, 'click', function() {
            infowindow.open(map,marker);
          });
        }

        document.initMap = function() {
          initialize_gmap();
        };

        var maps_api_key = 'AIzaSyBqDsRurx59ZSkMH_KTeJCJXFPErHskCe4';
        var maps_callback = 'document.initMap';
        var maps_api_url = 'https://maps.google.com/maps/api/js';
        var maps_script_tag = document.createElement('script');
        maps_script_tag.setAttribute('type', 'text/javascript');
        maps_script_tag.setAttribute('src', maps_api_url + '?key=' + maps_api_key + '&callback=' + maps_callback);
        maps_script_tag.setAttribute('defer', '');
        maps_script_tag.setAttribute('async', '');
        document.getElementsByTagName('body')[0].appendChild(maps_script_tag);

        jQuery('.simcal-events-list-container').slick({
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

jQuery(document).ready(function($) {
  var $transformer = $('.transformer'),
      $menuToggle = $('.menu-toggle');

  // Attaches event handler when .menu-toggle is clicked
  $menuToggle.on('click', function(event) {
    event.preventDefault();
    $transformer.toggleClass('is-open');
    $('.menu-toggle').toggle(function() {
      $('.menu-toggle').html('Less');
    }, function () {
      $('.menu-toggle').text('More');
    });
  });
});
